import React, { useContext, useEffect } from 'react';
import { AppContext } from '../Context';

const PreviewCardHeader = (props: any) => {
  const { heading, previewStepValue, isEditButton = true } = props;
  const { handleChangePageVal, handleChangeStepVal, toggleEditMode } = useContext(AppContext);

  return (
    <div className="outer-header-previewcard">
      <div className="header-previewcard">{heading}</div>
      {!isEditButton ? (
        ''
      ) : (
        <a
          className="edit-previewcard"
          onClick={() => {
            handleChangePageVal(2);
            handleChangeStepVal(previewStepValue);
            toggleEditMode();
          }}
        >
          EDIT
        </a>
      )}
    </div>
  );
};

export default PreviewCardHeader;
