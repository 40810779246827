import React from 'react';
import PreviewCardHeader from '../componentUtils/previewCardHeader';
import { Button, Card } from '@mui/material';
import './preview.css';
import moment from 'moment';
import dayjs from 'dayjs';

const PreviewPersonal = ({ profile, isEditButton }) => {
  return (
    <Card>
      <div style={{ margin: '16px' }}>
        <PreviewCardHeader heading={'Personal Details'} previewStepValue={0} isEditButton={isEditButton} />
        <div className="info-wrapper">
          <div className="info-values">
            <div className="label">Name</div>
            <div className="value">
              {/* {profile?.metadata?.profile_data?.name || profile?.independent_profile_data?.name || 'Not Specified'} */}
              {profile?.graph_node_id ? (
                <Button
                  style={{ textDecoration: 'none', padding: 0, minWidth: 'auto', color: 'black', fontSize: '1.1rem' }}
                  onClick={() => window.open(`/family-tree?idOfUser=${profile?.graph_node_id}`, '_blank')}
                >
                  {profile?.metadata?.profile_data?.name}
                </Button>
              ) : (
                profile?.independent_profile_data?.name
              )}
            </div>
          </div>
          <div className="info-values">
            <div className="label">Gautra</div>
            <div className="value">
              {profile?.metadata?.profile_data?.gautra || profile?.independent_profile_data?.gautra || 'Not Specified'}
            </div>
          </div>
          <div className="info-values">
            <div className="label">Subcaste</div>
            <div className="value">
              {profile?.metadata?.profile_data?.subcaste ||
                profile?.independent_profile_data?.subcaste ||
                'Not Specified'}
            </div>
          </div>
          <div className="info-values">
            <div className="label">Gender</div>
            <div className="value">{profile?.gender || 'Not Specified'}</div>
          </div>

          {profile?.independent_profile_data && (
            <>
              <div className="info-values">
                <div className="label">Father Name</div>
                <div className="value">{profile?.independent_profile_data?.father_name || 'Not Specified'}</div>
              </div>
              <div className="info-values">
                <div className="label">Phone Number</div>
                <div className="value">{profile?.independent_profile_data?.contact_number || 'Not Specified'}</div>
              </div>
            </>
          )}
          <div className="info-values">
            <div className="label">About me</div>
            <div className="value">{profile?.about_me || 'Not Specified'}</div>
          </div>
          <div className="info-values">
            <div className="label">Place of Birth</div>
            <div className="value">
              {profile?.place_of_birth?.place_name || profile?.place_of_birth?.formatted_address || 'Not Specified'}
            </div>
          </div>
          <div className="info-values">
            <div className="label">Date of birth</div>
            <div className="value">
              {profile?.date_of_birth ? moment(profile?.date_of_birth).format('YYYY-MM-DD') : 'Not Specified'}
            </div>
          </div>
          <div className="info-values">
            <div className="label">Time of Birth</div>
            <div className="value">{profile?.time_of_birth === '' ? 'Not Specified' : profile?.time_of_birth}</div>
          </div>
          <div className="info-values">
            <div className="label">Home Address</div>
            <div className="value">
              {profile?.home_address?.street || profile?.home_address?.formatted_address || 'Not Specified'}
            </div>
          </div>
          <div className="info-values">
            <div className="label">Village</div>
            <div className="value">
              {profile?.metadata?.profile_data?.village ||
                profile?.independent_profile_data?.village ||
                'Not Specified'}
            </div>
          </div>
          <div className="info-values">
            <div className="label">Height</div>
            <div className="value">{profile?.height || 'Not Specified'}</div>
          </div>
          <div className="info-values">
            <div className="label">Mangalik</div>
            <div className="value">{profile?.mangalik === ' ' ? 'Not Specified' : profile?.mangalik}</div>
          </div>
          <div className="info-values">
            <div className="label">Marital Status</div>
            <div className="value">{profile?.marital_status === ' ' ? 'Not Specified' : profile?.marital_status}</div>
          </div>
          {/* <div className="info-values">
            <div className="label">Blood group</div>
            <div className="value">{profile?.bloodGroup.value == '' ? 'Not Specified' : profile?.bloodGroup.value}</div>
          </div> */}
          <div className="info-values">
            <div className="label">Weight</div>
            <div className="value">{profile?.weight == '' ? 'Not Specified' : profile?.weight}</div>
          </div>

          <div className="info-values">
            <div className="label">Color</div>
            <div className="value">{profile?.complexion == ' ' ? 'Not Specified' : profile?.complexion}</div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default PreviewPersonal;
